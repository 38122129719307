/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Component } from 'preact';
import PropTypes from 'prop-types';
import validationFactory from '%/utils/validation';
import CustomSelect from '../../CustomSelect';
import ItemCard from '../ItemCard';
import { setModelInformations } from '../../../stores/FinancingSimulator';

export default class ChooseVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unit: {},
      model: {},
      versions: [],
      versionsToSelect: [],
      version: {},
      unitIsValid: false,
      modelIsValid: false,
      versionIsValid: false,
    };

    this.handleUnitChange = this.handleUnitChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
  }

  componentDidMount() {
    validationFactory(`#${this.props.formId}`);
  }

  handleUnitChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: this.props.units.filter(unit => unit.value === value)[0],
      unitIsValid: true,
    });
  }

  handleModelChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: this.props.models.filter(model => model.value === value)[0],
      versions: [],
      versionsToSelect: [],
      version: {},
      modelIsValid: true,
    });

    this.getVersions();
    this.historyPushState();
  }

  handleVersionChange(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      [name]: prevState.versions.filter(version => version.slug === value)[0],
      versionIsValid: true,
    }));

    // console.log(this.state.version);

    this.historyPushState(true);
  }

  handleNextStep(e) {
    e.preventDefault();

    const formInputs = [
      {
        element: this.unitInput,
        stateKey: 'unitIsValid',
      },
      {
        element: this.modelInput,
        stateKey: 'modelIsValid',
      },
      {
        element: this.versionInput,
        stateKey: 'versionIsValid',
      },
    ];

    formInputs.forEach(({ element, stateKey }) => {
      const { selectElement } = element;

      if (selectElement.value === '') {
        this.setState({
          [stateKey]: false,
        });

        e.stopImmediatePropagation();
      } else {
        this.setState({
          [stateKey]: true,
        });
      }
    });

    this.saveModelInformation();
  }

  get disableVersions() {
    return this.state.versions.length === 0;
  }

  getVersions() {
    if (this.state.model === undefined) return;

    window.service.getNewModelDetails(this.state.model.clone_id).then(res => {
      const filteredVersions = res.entries.map(version => ({
        value: version.slug,
        label: version.name,
      }));

      this.setState({ versions: res.entries });
      this.setState({ versionsToSelect: filteredVersions });
    });
  }

  getActiveModel() {
    return this.state.model !== undefined
      ? this.state.model.value
      : this.props.model;
  }

  getActiveVersion() {
    return this.state.version !== undefined
      ? this.state.version.slug
      : this.props.version;
  }

  getNewUrl(withVehicle) {
    if (this.state.version === undefined) return '';

    const pathNameParts = window.location.pathname.split('/');
    const channelSlug =
      pathNameParts[1] !== 'simulador-financiamento' ? pathNameParts[1] : '';
    const newPathname = channelSlug
      ? `${channelSlug}/simulador-financiamento/`
      : 'simulador-financiamento/';

    if (withVehicle) {
      const newSlugModel = `${this.state.model ? this.state.model.value : ''}`;
      const newSlugVersion = `${
        this.state.version ? this.state.version.slug : ''
      }`;
      const newSlugVehicle = `${newSlugModel}/${newSlugVersion}`;

      return `${window.location.origin}/${newPathname}${newSlugVehicle}${window.location.search}`;
    }

    return `${window.location.origin}/${newPathname}${window.location.search}`;
  }

  historyPushState(withVehicle = false) {
    if (this.props.model && this.props.version) return;

    if (window.history.pushState) {
      window.history.pushState({}, null, this.getNewUrl(withVehicle));
    }
  }

  saveModelInformation() {
    const { unit, model, version } = this.state;
    const { simulation_fuel_year, simulation_model_id } = version;

    if (!model || !version) return;

    const modelInformation = {
      unit: unit && unit.label,
      productId: model.clone_id,
      brand: version.brand.name,
      model: model.label,
      version: version.slug,
      modelYear: version.model_year,
      fabricationYear: version.fabrication_year,
      modelThumb: version.item_image && version.item_image.middle_image,
      modelValue: version.price,
      simulationFuelYear: simulation_fuel_year,
      simulationModelId: simulation_model_id,
    };

    setModelInformations(modelInformation);
  }

  objectIsFilled(object) {
    if (object === undefined) return false;

    return Object.keys(object).length > 0;
  }

  render() {
    const { units, models, formId } = this.props;

    const {
      unit,
      model,
      version,
      versionsToSelect,
      unitIsValid,
      modelIsValid,
      versionIsValid,
    } = this.state;

    return (
      <div className="choose-vehicle">
        <h2>Selecione o modelo e a unidade desejada</h2>
        <h6>
          Escolha o modelo, versão e a loja com a qual você deseja negociar.
        </h6>

        <hr />

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-3">
            <div className="choose-vehicle__form">
              <form id={formId}>
                {units && (
                  <div className="form-group">
                    <label htmlFor="unit">Unidade</label>
                    <CustomSelect
                      handleSelectChange={this.handleUnitChange}
                      name="unit"
                      options={units}
                      value=""
                      placeholderValue="Escolha uma unidade"
                      searchEnabled={false}
                      ref={unitInput => (this.unitInput = unitInput)}
                    />

                    {!unitIsValid && (
                      <div
                        id="invalid-unit"
                        className="invalid-feedback is-invalid-unit"
                      >
                        <div className="error-message" id="bouncer-error_unit">
                          Por favor, selecione esse campo
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {models && (
                  <div className="form-group">
                    <label htmlFor="model">Modelo</label>

                    <CustomSelect
                      handleSelectChange={this.handleModelChange}
                      name="model"
                      options={models}
                      value={this.getActiveModel()}
                      placeholderValue="Escolha o modelo"
                      searchEnabled={false}
                      ref={modelInput => (this.modelInput = modelInput)}
                    />

                    {!modelIsValid && (
                      <div
                        id="invalid-model"
                        className="invalid-feedback is-invalid-model"
                      >
                        <div className="error-message" id="bouncer-error_model">
                          Por favor, selecione esse campo
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {versionsToSelect && (
                  <div className="form-group">
                    <label htmlFor="version">Versão</label>

                    <CustomSelect
                      handleSelectChange={this.handleVersionChange}
                      name="version"
                      options={versionsToSelect}
                      value={this.getActiveVersion()}
                      placeholderValue="Escolha uma versão"
                      disable={this.disableVersions}
                      searchEnabled={false}
                      ref={versionInput => (this.versionInput = versionInput)}
                    />

                    {!versionIsValid && (
                      <div
                        id="invalid-version"
                        className="invalid-feedback is-invalid-version"
                      >
                        <div
                          className="error-message"
                          id="bouncer-error_version"
                        >
                          Por favor, selecione esse campo
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <button
                  type="button"
                  className="btn btn-next button--primary ml-auto font-weight-bold w-100 d-none d-md-block"
                  onClick={this.handleNextStep}
                >
                  Próximo
                </button>
              </form>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-4">
            <div className="choose-vehicle__card">
              {this.objectIsFilled(version) && (
                <ItemCard
                  modelName={model.label}
                  version={version.name}
                  unit={unit && unit.label}
                  thumb={version.item_image && version.item_image.middle_image}
                  thumbAlt={version.name}
                  textPrice="A partir de"
                  price={version.price}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row d-md-none">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <button
              type="button"
              className="btn btn-next button--primary ml-auto font-weight-bold w-100"
              onClick={this.handleNextStep}
            >
              Próximo
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ChooseVehicle.defaultProps = {
  formId: '',
  units: [],
  models: [],
  model: '',
  version: '',
};

ChooseVehicle.propTypes = {
  formId: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  models: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      clone_id: PropTypes.number,
    }),
  ),
  model: PropTypes.string,
  version: PropTypes.string,
};
