export class DataHandler {
  mergeData(data) {
    if (!data) return [];

    const [autodromoData, hubData = [], marketPlaceData = []] = data;
    const maxLength = Math.max(
      autodromoData.length,
      hubData.length,
      marketPlaceData.length,
    );
    const mergedData = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < maxLength; i++) {
      if (i < autodromoData.length) {
        mergedData.push(autodromoData[i]);
      }

      if (hubData && i < hubData.length) {
        mergedData.push(hubData[i]);
      }

      if (marketPlaceData && i < marketPlaceData.length) {
        mergedData.push(marketPlaceData[i]);
      }
    }

    return mergedData;
  }
}
