import '../showroom';
import './components';
import './stores';
import { unitsSelectSetupOnLoad } from '../auto/unitsSelect';
import { makeFiatDdmOffersListSyncService } from '../auto/services/ddm_service';
import { DDMAdapter } from '../auto/services/ddm_service/adapters/ddmAdapter';
import { setDropdownInteration } from '../auto/utils/components';
import { addExceptionModelsToChannelData } from '../auto/services/ddm_service/utils';

window.ddmOffersListSyncService = makeFiatDdmOffersListSyncService();
window.DDMAdapter = DDMAdapter;
window.helpers = {
  ...window.helpers,
  addExceptionModelsToChannelData,
};

window.components = {
  setDropdownInteration,
};

$(document).ready(function () {
  unitsSelectSetupOnLoad();
});
