/* eslint-disable no-underscore-dangle */
export class SortHandler {
  apply(sortParams, baseData = []) {
    const sortHandlers = {
      name: this.orderByNameAsc,
      '-name': this.orderByNameDesc,
      price_value: this.orderByPriceAsc,
      '-price_value': this.orderByPriceDesc,
      default: data => data,
    };

    const currentSortHandler = sortHandlers[sortParams] || sortHandlers.default;
    const bindedSortHandler = currentSortHandler.bind(this);

    const orderedData = bindedSortHandler(baseData);

    return orderedData;
  }

  orderByPriceAsc(basedData) {
    return this._orderByPrice('asc', basedData);
  }

  orderByPriceDesc(basedData) {
    return this._orderByPrice('desc', basedData);
  }

  orderByNameAsc(basedData) {
    return this._orderByName('asc', basedData);
  }

  orderByNameDesc(basedData) {
    return this._orderByName('desc', basedData);
  }

  _orderByPrice(type = 'asc', basedData = []) {
    const orderedData = basedData;

    if (type === 'asc') {
      orderedData.sort((a, b) => a.price - b.price);
    } else if (type === 'desc') {
      orderedData.sort((a, b) => b.price - a.price);
    }

    return orderedData;
  }

  _orderByName(type = 'asc', basedData = []) {
    if (type === 'asc') {
      return basedData.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (type === 'desc') {
      return basedData.sort((a, b) => b.name.localeCompare(a.name));
    }

    return basedData;
  }
}
