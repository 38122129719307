/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { ServiceAdapter } from './serviceAdapter';

export class AutodromoAdapter extends ServiceAdapter {
  constructor(service) {
    super();
    this.service = service;
  }

  async getOffers() {
    try {
      const response = await this.service.getOffers({
        per_page: -1,
        sort: "ordination"
      }, true);
      const data = this._adaptOffersSchema(response.entries);

      return data;
    } catch (error) {
      return [];
    }
  }

  _adaptOffersSchema(offers) {
    return offers.map(offer => {
      const {
        id,
        item_image,
        name,
        model,
        brand,
        seal_list,
        offer_type,
        item_offer,
        price,
        offer_category,
        slug,
        expired_at,
      } = offer;

      const parsedPrice = price
        ? parseFloat(price.replace(/\./g, '').replace(',', '.'))
        : null;

      return {
        id,
        base_type: 'Autodromo',
        thumbs: item_image,
        name,
        model,
        brand,
        seal_list,
        offer_type,
        item_offer,
        price: parsedPrice,
        category: offer_category,
        slug,
        expired_at,
      };
    });
  }
}
