import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class NewOffersRAM extends Component {
  lowerCasedBadge(badge) {
    return badge.toLowerCase();
  }

  render() {
    const { title, image, link, lazy, sealList, offer, showBadge, badge } =
      this.props;

    return (
      <div className="new-offers-card">
        <a className="new-offers-card__link" href={link}>
          <img
            className={classNames('new-offers-card__image', {
              'swiper-lazy': lazy,
            })}
            src={image}
            alt={title}
            width="200"
            height="145"
          />
        </a>
        <div className="new-offers-card__content">
          <h3 className="new-offers-card__title">{title}</h3>
          <ul
            className="new-offers-card__highlights"
            data-match-height="new-offers-card__highlights"
          >
            {sealList.map(item => (
              <li className="new-offers-card__highlight">
                <i className="icon icon-chevron" />
                {item}
              </li>
            ))}
          </ul>
          <div
            className="new-offers-card__price"
            data-match-height="new-offers-card__price"
          >
            {offer.secondary_call && (
              <p className="new-offers-card__starting-price">
                {offer.secondary_call}
              </p>
            )}
            {offer.main_call && (
              <small className="new-offers-card__text-by">
                {offer.main_call}
              </small>
            )}
            {offer.featured && (
              <div className="new-offers-card__promotional-price">
                {offer.featured}
              </div>
            )}
            {offer.description && (
              <div className="new-offers-card__description">
                {offer.description}
              </div>
            )}
          </div>
          <a className="btn button button--large button--primary" href={link}>
            Tenho interesse
          </a>
        </div>

        {showBadge && (
          <div className="new-offers-card__badge">
            <small>{this.lowerCasedBadge(badge)}</small>
          </div>
        )}
      </div>
    );
  }
}

NewOffersRAM.defaultProps = {
  title: '',
  image: '',
  link: '',
  lazy: false,
  sealList: [],
  offer: {},
  showBadge: false,
  badge: '',
};

NewOffersRAM.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  sealList: PropTypes.arrayOf(PropTypes.string),
  lazy: PropTypes.bool,
  offer: PropTypes.shape({
    secondary_call: PropTypes.string,
    main_call: PropTypes.string,
    featured: PropTypes.string,
    description: PropTypes.string,
  }),
  showBadge: PropTypes.bool,
  badge: PropTypes.string,
};
