import { createStore, createEvent, createEffect } from 'effector';

const fetchList = () => {
  const { page, perPage, filters, sort } =
    window.store.DDMOffersFilter.getState();

  const offersData = window.ddmOffersRepository.list({
    page,
    perPage,
    filters,
    sort,
  });

  return offersData;
};

export const setList = createEffect('set list').use(fetchList);
export const incrementList = createEffect('increment list').use(fetchList);

export const setPage = createEvent('set page');
export const setPerPage = createEvent('set perPage');
export const nextPage = createEvent('next page');
export const addFilter = createEvent('add filter');
export const removeFilter = createEvent('remove filter');
export const setSort = createEvent('set sort');
export const setLoading = createEvent('set loading');

const DDMOffersFilter = (() => {
  let instance;

  return (
    defaultValue = {
      list: [],
      page: 1,
      perPage: 12,
      totalPages: 0,
      loading: true,
      loadingMore: false,
      filters: {},
      sort: '',
      auxMethods: { setList, setLoading },
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setList.pending, (state, loading) => ({
        ...state,
        loading,
      }));

      instance.on(setList.fail, state => ({
        ...state,
        loading: false,
      }));

      instance.on(setList.done, (state, res) => ({
        ...state,
        list: res.result.data,
        totalPages: res.result.totalPages,
        loading: false,
      }));

      instance.on(incrementList.pending, (state, loadingMore) => ({
        ...state,
        loadingMore,
      }));

      instance.on(incrementList.fail, state => ({
        ...state,
        loadingMore: false,
      }));

      instance.on(incrementList.done, (state, res) => ({
        ...state,
        list: [...state.list, ...res.result.data],
        loadingMore: false,
      }));

      instance.on(setPage, (state, page) => ({
        ...state,
        page,
      }));

      instance.on(nextPage, state => ({
        ...state,
        page: state.page + 1,
      }));

      instance.on(setPerPage, (state, perPage) => ({
        ...state,
        perPage,
      }));

      instance.on(setLoading, (state, loading) => ({
        ...state,
        loading,
      }));

      // filters

      instance.on(addFilter, (state, filter) => ({
        ...state,
        filters: {
          ...state.filters,
          ...filter,
        },
      }));

      instance.on(removeFilter, (state, key) => {
        const filters = state.filters;
        delete filters[key];

        return {
          ...state,
          filters,
        };
      });

      instance.on(setSort, (state, sort) => ({
        ...state,
        sort,
      }));
    }

    return instance;
  };
})();

export default DDMOffersFilter;
