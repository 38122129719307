import { Component } from 'preact';
import PropTypes from 'prop-types';
import ConversionForm from '../../../../ConversionFormNoResult/ConversionForm';

class ConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
    };

    window.store.DDMOffersFilter.watch(state => {
      this.setState({
        showConversionForm: state.list.length === 0 && state.loading === false,
        keyword: state.keyword,
      });
    });

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });

    const customParms = {
      ...params,
      product: params.product || this.state.keyword,
    };

    return window.serviceConversion
      .convert(customParms)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false,
        });
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true,
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: true,
        });
      });
  }

  render() {
    const {
      isSubmittingForm,
      errorSendingForm,
      showOverlay,
      showConversionForm,
    } = this.state;
    const {
      formId,
      mainPhrase,
      linkPrivacyPolicy,
      units,
      channel,
      category,
      showUnits,
      showCpf,
    } = this.props;

    if (showConversionForm) {
      return (
        <div className="conversion-form-no-result">
          <ConversionForm
            className="form-conversion--light"
            title="Não encontrou o que procurava?"
            formId={formId}
            isSubmittingForm={isSubmittingForm}
            errorSendingForm={errorSendingForm}
            showOverlay={showOverlay}
            handleSubmit={this.handleSubmit}
            handleCloseOverlay={this.handleCloseOverlay}
            mainPhrase={mainPhrase}
            checkStyle="dark"
            linkPrivacyPolicy={linkPrivacyPolicy}
            units={units}
            channel={channel}
            category={category}
            showUnits={showUnits}
            showCpf={showCpf}
            versions={this.props.versions}
            shouldShowDataPermissions={this.props.shouldShowDataPermissions}
            dataPermissionsCustomText={this.props.dataPermissionsCustomText}
          />
        </div>
      );
    }

    return null;
  }
}

ConversionFormApp.defaultProps = {
  mainPhrase:
    'Preencha o formulário abaixo e um de nossos consultores entrarão em contato para te ajudar.',
  units: [],
  showUnits: true,
  showCpf: false,
  versions: [],
  shouldShowDataPermissions: false,
  linkPrivacyPolicy: null,
  channel: null,
  category: null,
  dataPermissionsCustomText: null,
};

ConversionFormApp.propTypes = {
  formId: PropTypes.string.isRequired,
  mainPhrase: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

export default ConversionFormApp;
