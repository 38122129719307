/* eslint-disable consistent-return */
import { DDMAdapter, AutodromoAdapter } from '../adapters';
import { getFetchServicesList, sanitizeList } from '../utils';
import { makeDDMOffersListSyncService } from './ddmOffersListSyncServiceFactory';

export function makeRamDdmOffersListSyncService() {
  const ddmOffersListSyncService = makeDDMOffersListSyncService();

  const servicesAdaptersKeys = {
    autoServiceApi: AutodromoAdapter,
    hubService: DDMAdapter,
    serviceFca: DDMAdapter,
  };

  const fetchServicesList = getFetchServicesList(servicesAdaptersKeys);
  const filteredFetchServicesList = sanitizeList(fetchServicesList);

  return () => {
    ddmOffersListSyncService.execute(filteredFetchServicesList);
  };
}
